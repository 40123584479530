window.onSubmit = function(token) {
	var url = "https://aorq6rnsz0.execute-api.us-west-2.amazonaws.com/Prod/mailerSend"
	var data = {				
		subject: 'Nueva Petición | ' + $('#contactFirstName').val() + ' ' + $('#contactLastName').val() + ' (' + $('#contactFormEmail').val() + ')',
		message: $('#contactFormMessage').val(),
		token: token
	  };
	$.ajax({
		type: "POST",
		url : url,
		dataType: "json",
		crossDomain: "true",
		contentType: "application/json; charset=utf-8",
		data: JSON.stringify(data),

		beforeSend: function() {
			//$('.zmdi').removeClass('zmdi-long-arrow-right');
			//$('.zmdi').addClass('zmdi-spinner zmdi-hc-spin');
		},

		success: function () {
			$('.success-message').removeClass('invisible');
			$('.error-message').addClass('invisible');
			document.getElementById("mainContactForm").reset();
		}, 
		
		error: function () {
			$('.success-message').addClass('invisible');
			$('.error-message').removeClass('invisible');
		}

	});
}